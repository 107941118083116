import { Box, Grid } from '@mui/material'
import React from 'react'

type Logo = {
    image: string
    url: string
} 

const ImageGrid = () => {
    const logoArr : Logo[] = [
        { image: "drf.png", url: "https://www.dormroomfund.com" },
        { image: "lumos.jpg", url: "https://www.lumos.com" },
        { image: "anduril.jpg", url: "https://www.anduril.com" },
        { image: "ramp.jpg", url: "https://www.ramp.com" },
        { image: "roblox.jpg", url: "https://www.roblox.com" },
        { image: "therounds.jpg", url: "https://www.therounds.co" }
    ]    

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{
                    xs: 1,
                    md: 4
                }}
            >
                {logoArr.map(logo => 
                    <Grid item>
                        <Box component="div">
                            <a href={logo.url} target="_blank">
                                <img style={{
                                    borderRadius: "50px",
                                    marginTop: "20px",
                                }} src={"images/" + logo.image} id="logoImage"/>
                             </a>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    )
}


export default ImageGrid