import React from 'react';
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  },
  typography: {
      h1: {
        fontSize: 90,
        '@media (max-width:600px)': {
          fontSize: 60,
        },
        fontWeight: 700,
      },  
      h3: {
        fontWeight: 300,
      },  
      body1: {
          fontSize: 27,
          fontWeight: 200,
          color: "white",
          lineHeight: '32.4px',
          '@media (max-width:600px)': {
            fontSize: 18,
            lineHeight: '22px',
          },
      },
      body2: {
        fontSize: 20, 
        fontWeight: 150,
        color: "white",
        lineHeight: '32.4px',
        '@media (max-width:600px)': {
          fontSize: 16,
          lineHeight: '22px',
        },
      },     
      fontFamily: [
          "Raleway",
          '-apple-system',
          '"Helvetica Neue"',
          'sans-serif',
        ].join(','),
    },
});