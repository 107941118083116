import { Box, Button, Grid, Typography } from '@mui/material'
import React, { Component, useEffect, useState } from 'react'
import { NotionRenderer,  BlockMapType,  } from 'react-notion'
import data from "../res.json"
import { toast } from "react-toastify"
import Home from './Home'

type BlogPost = {
    title: string
    markdownContent: string
    url?: string
}

const DEFAULT_BLOG_POSTS = [
    {
        title: "One of Neom's housing complexes for employees.",
        markdownContent: "",
        url: ""
    },
    {
        title: "Another article woodbridge what the fuck",
        markdownContent: "",
        url: ""
    },
    {
        title: "Another article woodbridge what the fuck",
        markdownContent: "",
        url: ""
    },  
    {
        title: "Another article woodbridge what the fuck",
        markdownContent: "",
        url: ""
    },     
]

const Reading = () => {    
    const [readingList, setReadingList] = useState<BlogPost[]>(DEFAULT_BLOG_POSTS)
    const live = false

    if (!live) {
        toast.info("Coming soon!", {
            position: toast.POSITION.BOTTOM_CENTER
        });
        return <><Home/></>
    }
      
    return (        
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <Box sx = {{
                display: "flex",
                height: "100%",
                maxWidth: [
                    "300px",
                    "600px",
                ],
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
            }}
            component="div"            
            >
                <Grid 
                    container
                    direction="column" 
                    spacing={{
                        xs: 2.5,
                        md: 4
                    }}
                    justifyContent="center"
                    alignItems="center"
                >   
                    <Grid item>
                        <Typography variant="h1" textAlign="center"><u>reading</u></Typography>    
                    </Grid>                     
                   {readingList.map((blogPost, index) => 
                        <Grid item>
                            <Typography variant="body1" textAlign="center"><i>{index + 1}. {blogPost.title}</i></Typography>
                        </Grid>
                    )}               
                    <Grid item>
                        <Button sx = {{
                            textTransform: "lowercase",
                            paddingLeft: "40px", 
                            paddingRight: "40px",
                            borderRadius: "10px",
                            backgroundColor: "rgba(255, 255, 255, 0.1)"
                        }}>
                            <Typography variant="body2">i'm feeling lucky</Typography>
                        </Button>    
                    </Grid>     
                </Grid>
            </Box>
        </div>
    )
}

export default Reading


/*
const [data, setData] = useState<BlockMapType>()    
const fetchData = async () => {
    const d = await fetch(
        "https://notion-api.splitbee.io/v1/page/b60d255e2f8341ab825158a372978617"
    )
    const json = await d.json()
    setData(json)
}

useEffect(() => {
    fetchData()
}, [])*/