import React, { useEffect, useRef, useState } from 'react';
import { Container, useMediaQuery } from '@mui/material';
import Home from './pages/Home';
import Reading from './pages/Reading'
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei'
import type { Camera } from 'three'
import Menu from './components/Menu';
import RotatingStars from './components/RotatingStars';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BottomInfo from './components/BottomInfo';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const virtualCamera = useRef<Camera>()
  // Show camera as smaller for mobile.
  const [position, setPosition] = useState<[number, number, number]>([0, 0, 2])

  return (
    <BrowserRouter>
      <Container  style = {{ height: "100vh" }}  maxWidth={false}> 
        <Canvas camera={{ position: position }}>
          <PerspectiveCamera ref={virtualCamera} aspect={33}>
            <RotatingStars/>
          </PerspectiveCamera>   
          <OrbitControls camera={virtualCamera.current} />   
        </Canvas>    
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/reading" element={<Reading/>}/>
          <Route path="/writing" element={<Reading/>}/>
        </Routes>                       
        <Menu/>    
        <BottomInfo />
        <ToastContainer />
      </Container>
    </BrowserRouter>    
  );
}

export default App;
