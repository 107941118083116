import React, { useRef, useState } from 'react';
import * as random from 'maath/random'
import { useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei'

const RotatingStars = (props: any) => {
    const ref : any | null = useRef()
    const [sphere] = useState(() => random.inSphere(new Float32Array(3000), { radius: 1 }))
    useFrame((state, delta) => {
      if (ref.current != null) {
        ref.current.rotation.x -= delta / 10
        ref.current.rotation.y -= delta / 15
      }
    })
    return (
      <group rotation={[0, 0, Math.PI / 4]}>
        <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial transparent color="#ebebeb" size={0.0045} sizeAttenuation={true} depthWrite={false} />
        </Points>
      </group>
    )
  }

export default RotatingStars