import { Box, Button, Container, Fade, Grid, Link, Typography } from '@mui/material'
import Hamburger from 'hamburger-react'
import React, { useState } from 'react'

const Menu = () => {
    const [toggled, setToggled] = useState(false)

    return (
        <Container sx = {{ 
            position: "absolute" ,
            top: "20px",
            left: "8px",
        }} maxWidth={false}>
            <Hamburger toggled={toggled} toggle={setToggled} color="white" /> 
            {toggled && 
                <Box component="div">
                    <Fade in={toggled}>
                        <Grid direction="column" spacing={3}>
                            <Grid item>
                                <Link href="/" variant="body2">home</Link>
                            </Grid>
                            <Grid item>
                                <Link href="/reading" variant="body2">reading</Link>
                            </Grid>
                            <Grid item>
                                <Link href="/writing" variant="body2">writing</Link>
                            </Grid>
                        </Grid>
                    </Fade>                    
                </Box>               
            }
        </Container>
    )
}

export default Menu