import * as THREE from 'three'
import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import React, { useState, useRef } from 'react';
import { Canvas, useFrame, ThreeElements } from '@react-three/fiber'
import ImageGrid from '../components/ImageGrid';
import Hamburger from 'hamburger-react';

const COLUMN_SPACING = 4

const Home = () => {
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <Box sx = {{
                display: "flex",
                height: "100%",
                maxWidth: [
                    "300px",
                    "600px",
                ],
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
            }}
            component="div"            
        >
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={COLUMN_SPACING}>
                    <Grid item>
                        <Typography variant="h1">hi, I'm danny</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align='center' variant="body1">a student at UPenn & full stack engineer, w/ interests in <u>cryptography</u>, <u>distributed systems</u>, <u>design</u>, and <u>operations</u>.</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align='center' variant="body1">I love jazz, being in the water, reading, and playing/watching poker.</Typography>
                    </Grid>
                    <Grid item>
                        <ImageGrid/>
                    </Grid>
                </Grid> 
            </Box> 
        </div>  
    )
}

export default Home