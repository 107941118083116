import { Box, Grid, Typography, Button } from '@mui/material'
import React from 'react'

const GITHUB_URL = "https://github.com/dannybess"

const BottomInfo = () => {
    return (
        <div style = {{ position: "absolute", bottom: 0, left: 0, right: 0, marginBottom: 30, width: "100%" }}>
            <Box sx = {{
                display: "flex",
                justifyContent: "center",
            }}
            component="div"
            >
                <Grid container direction="row" justifyContent="center" alignItems="center" width="100%" columnSpacing={2}>  
                        <Grid item>
                            <Button color="primary" sx = {{
                                typography: "body2",
                                textTransform: "none"
                            }} onClick={() => {
                                window.open(
                                    GITHUB_URL,
                                    "_blank"
                                )
                            }}>/github</Button>
                        </Grid>                           
                        <Grid item>
                            <Button color="primary" sx = {{
                                typography: "body2",
                                textTransform: "none"
                            }} onClick={() => {
                                window.open(
                                    "/documents/resume.pdf"
                                )
                            }}>/resume</Button>
                        </Grid>                           
                        <Grid item>
                            <Button color="primary" sx = {{
                                typography: "body2",
                                textTransform: "none"
                            }} onClick={() => {
                                window.open(
                                    "mailto:daniel@dormroomfund.com"
                                )
                            }}>/contact</Button>
                        </Grid>    
                </Grid>
            </Box>     
        </div>
    )
}

export default BottomInfo